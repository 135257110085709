import React from 'react';
import Container from '../../UI/Container';
import styled from 'styled-components';
import { DESKTOP, SECONDARY_FONT } from '../../UI/Variables';
import 'rc-slider/assets/index.css';
import { NavLink } from 'react-router-dom';
import { profileIcon } from '../../UI/Icons';
import { useTranslation } from 'react-i18next';

const OtherProfile = () => {
  const { t } = useTranslation();
  return (
    <OtherProfileWrapper>
      <Container>
        <OtherProfileInner>
          <BackButton to='/voting'>
            {`<`} {t('USER.VOTING.PROFILE.BACK')}
          </BackButton>
          <OtherProfileTop>
            <Avatar>
              {profileIcon}
              <AvatarName>Oleksandr Petrov</AvatarName>
            </Avatar>
          </OtherProfileTop>
          <OtherProfileFields>
            <OtherProfileField>
              Job title<span>Head Chef</span>
            </OtherProfileField>
            <OtherProfileField>
              Department<span>Kitchen</span>
            </OtherProfileField>
            <OtherProfileField>
              Date of birth<span>01.04.1993</span>
            </OtherProfileField>
            <OtherProfileField>
              Email<span>head-chef@kitchen.mail.com</span>
            </OtherProfileField>
            <OtherProfileField>
              About me
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </span>
            </OtherProfileField>
          </OtherProfileFields>
        </OtherProfileInner>
      </Container>
    </OtherProfileWrapper>
  );
};

const OtherProfileWrapper = styled.div`
  padding: 30px 0 60px;
`;

const BackButton = styled(NavLink)`
  font-family: ${SECONDARY_FONT};
  font-weight: 700;
  text-decoration: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  color: var(--primary-color-1);

  @media ${DESKTOP} {
    &:hover {
      border-bottom: 2px solid var(--primary-color-2);
    }
  }
`;

const OtherProfileInner = styled.div`
  max-width: 500px;
  margin: auto;
`;

const OtherProfileTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`;

const Avatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img,
  svg {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
`;

const AvatarName = styled.div`
  font-size: 23px;
  font-weight: 700;
`;

const OtherProfileFields = styled.div``;

const OtherProfileField = styled.div`
  font-size: 14px;
  color: grey;

  span {
    color: #000;
    font-weight: 400;
    display: block;
    margin-top: 3px;
    font-size: 17px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-color);
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
`;

export default OtherProfile;
