import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Main, Navigation, Votes, Average } from './index';
import NotFound from '../../NotFound/NotFound';

const Metrics = () => {
  return (
    <>
      <Navigation />
      <Routes>
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={<Main />} />
        <Route path='votes' element={<Votes />} />
        <Route path='average' element={<Average />} />
      </Routes>
    </>
  );
};

export default Metrics;
