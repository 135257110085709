import React from 'react';
import Container from "../../UI/Container";

const FAQ = () => {
    return (
        <Container>
            FAQ<br/>
            FAQ<br/>
            FAQ<br/>
            FAQ<br/>
            FAQ<br/>
            FAQ<br/>
        </Container>
    );
}

export default FAQ;
