import React from 'react';
import Container from "../../UI/Container";

const MetricsAverage = () => {
    return (
        <Container>MetricsAverage</Container>
    );
}

export default MetricsAverage;
