import React, { useState } from 'react';
import api from 'services/api';
import Container from '../UI/Container';
import styled from 'styled-components';
import 'rc-slider/assets/index.css';
import { useTranslation } from 'react-i18next';
import {
  buttonHover,
  buttonStyles,
  formErrorStyles,
  formFieldWrapperStyles,
  headerBig,
  headerMedium,
} from '../UI/UI';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validationSchemaRegistration } from '../../helpers/validationSchema';

const Registration = ({ setRole, onLogin, setSignUp }) => {
  const { t } = useTranslation();
  const [departmentsList, setDepartmentsList] = useState(
    JSON.parse(localStorage.getItem('departments')) || [],
  );
  const [departmentsError, setDepartmentsError] = useState(false);

  const [isStep2, setIsStep2] = useState(localStorage.getItem('isStep2'));
  const handleNextStep = async (values) => {
    try {
      const response = await api.post('/api/auth/register?voteEventId=1', {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
      });

      // Save list of avaliable departments
      localStorage.setItem('departments', JSON.stringify(response.data));
      setDepartmentsList(response.data);

      // Save the step of registration
      localStorage.setItem('isStep2', true);
      setIsStep2(true);

      // Save the token
      localStorage.setItem('token', response.headers.authorization);
    } catch (error) {
      console.error('Error while adding user:', error);
    }
  };

  const [selectedDepartmentId, setSelectedDepartmentId] = useState('');

  const handleSelectChange = (event) => {
    setSelectedDepartmentId(event.target.value);
    setDepartmentsError(event.target.value === '');
  };

  const finishRegistration = async () => {
    try {
      await api.patch(`/api/profile/finish-sign-up`, {
        departmentId: +selectedDepartmentId,
      });

      // Clean list of avaliable departments
      localStorage.removeItem('departments');

      // Clean the step of registration
      localStorage.removeItem('isStep2');

      // Settle the role
      setRole('user');
      localStorage.setItem('role', 'user');
      onLogin('user');

      // Exit the registration mode
      setSignUp(false);
    } catch (error) {
      setDepartmentsError(true);
      console.error('Error while posting department.', error);
    }
  };

  return (
    <RegistrationWrapper>
      <Container>
        <RegistrationTopTitle>REGISTRATION</RegistrationTopTitle>
        {!isStep2 && (
          <RegistrationStep1>
            <RegistrationTitle>
              Step 1. Please enter your information
            </RegistrationTitle>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                password: '',
              }}
              validationSchema={validationSchemaRegistration}
              onSubmit={(values) => handleNextStep(values)}>
              {({ errors, touched, values }) => (
                <Form>
                  <FieldWrapper>
                    <label htmlFor='firstName'>First Name</label>
                    <Field
                      name='firstName'
                      type='text'
                      style={
                        errors.firstName &&
                        touched.firstName && { borderColor: 'red' }
                      }
                    />
                    <ErrorMessage name='firstName' component='span' />
                  </FieldWrapper>

                  <FieldWrapper>
                    <label htmlFor='lastName'>Last Name</label>
                    <Field
                      name='lastName'
                      type='text'
                      style={
                        errors.lastName &&
                        touched.lastName && { borderColor: 'red' }
                      }
                    />
                    <ErrorMessage name='lastName' component='span' />
                  </FieldWrapper>

                  <FieldWrapper>
                    <label htmlFor='email'>Email</label>
                    <Field
                      name='email'
                      type='email'
                      style={
                        errors.email && touched.email && { borderColor: 'red' }
                      }
                    />
                    <ErrorMessage name='email' component='span' />
                  </FieldWrapper>

                  <FieldWrapper>
                    <label htmlFor='password'>Password</label>
                    <Field
                      name='password'
                      type='password'
                      style={
                        errors.password &&
                        touched.password && { borderColor: 'red' }
                      }
                    />
                    <ErrorMessage name='password' component='span' />
                  </FieldWrapper>

                  <FormButton type='submit'>Submit</FormButton>
                </Form>
              )}
            </Formik>
          </RegistrationStep1>
        )}
        {isStep2 && (
          <RegistrationStep2>
            <RegistrationTitle>
              Step 2. Please select your department
            </RegistrationTitle>
            <RegistrationSelectWrapper>
              <select
                value={selectedDepartmentId}
                onChange={handleSelectChange}>
                <option value=''>Select your department</option>
                {departmentsList &&
                  departmentsList.length > 0 &&
                  departmentsList.map((department, index) => (
                    <option key={index} value={department.id}>
                      {department.name}
                    </option>
                  ))}
              </select>
              {departmentsError && (
                <SelectErrorMessage>
                  {t('FORM_ERRORS.DEPARTMENTS.EMPTY')}
                </SelectErrorMessage>
              )}
            </RegistrationSelectWrapper>
            <FormButton onClick={finishRegistration}>Submit</FormButton>
          </RegistrationStep2>
        )}
      </Container>
    </RegistrationWrapper>
  );
};

const RegistrationWrapper = styled.div`
  padding: 60px 0;
`;

const RegistrationTopTitle = styled.div`
  ${headerBig};
  margin-bottom: 30px;
  text-align: center;
`;

const RegistrationTitle = styled.div`
  ${headerMedium};
  margin-bottom: 30px;
  text-align: center;
`;

const RegistrationStep1 = styled.div`
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
`;

const FieldWrapper = styled.div`
  ${formFieldWrapperStyles};
`;

const FormButton = styled.button`
  ${buttonStyles};
  ${buttonHover};

  width: 100%;
  font-size: 18px;
  margin-top: 30px;
`;

const RegistrationStep2 = styled.div`
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;

  select {
    width: 100%;

    color: black;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
    background-color: #f5f5f5;
    padding: 10px 20px;
  }
`;

const SelectErrorMessage = styled.div`
  ${formErrorStyles};
`;

const RegistrationSelectWrapper = styled.div`
  position: relative;
`;

export default Registration;
