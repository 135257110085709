import React from 'react';
import Container from "../../UI/Container";

const MetricsVotes = () => {
    return (
        <Container>MetricsVotes</Container>
    );
}

export default MetricsVotes;
