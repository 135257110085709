import React, { useState, useEffect } from 'react';
import api from 'services/api';
import styled, { createGlobalStyle } from 'styled-components';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import { UserVoting, UserOtherProfile } from './components/UserTabs';
import {
  Breadcrumbs,
  AdminHome,
  AdminInformation,
  AdminMetrics,
  AdminFAQ,
} from './components/AdminTabs';
import { menuItems as adminMenuItems } from './components/AdminTabs/menuItems';
import {
  AsideMenu,
  LangSwitcher,
  NotFound,
  Login,
  Registration,
  MyProfile,
} from './components';
import Container from './components/UI/Container';
import { profileIcon } from './components/UI/Icons';
import {
  activeLinkStyles,
  menuStyles,
  menuInnerStyles,
  burgerStyles,
} from './components/UI/UI';
import {
  MAIN_COLOR_1,
  MAIN_COLOR_2,
  COLOR_GREY,
  MAIN_FONT,
  MOBILE,
  SECONDARY_FONT,
} from './components/UI/Variables';

const App = () => {
  const [role, setRole] = useState(() => {
    return localStorage.getItem('role');
  });

  const [signUp, setSignUp] = useState(false);

  const handleLogin = (selectedRole) => {
    setRole(selectedRole);
  };

  // Check if user is logged in
  const [user, setUser] = useState([]);
  const getUser = async () => {
    try {
      const response = await api.get('/api/profile');
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getUser();
  }, [role]);

  const logOut = () => {
    setRole(null);
    localStorage.removeItem('role');
    localStorage.removeItem('token');
  };

  return (
    <Router>
      <GlobalStyles />
      {role === null &&
        (signUp ? (
          <Registration
            setRole={setRole}
            onLogin={handleLogin}
            setSignUp={setSignUp}
          />
        ) : (
          <Login
            setRole={setRole}
            onLogin={handleLogin}
            setSignUp={setSignUp}
          />
        ))}
      {role === 'user' && <UserRoutes user={user} handleLogOut={logOut} />}
      {role === 'admin' && <AdminRoutes user={user} handleLogOut={logOut} />}
    </Router>
  );
};

const UserRoutes = ({ user, handleLogOut }) => {
  const { width } = useWindowSize();
  const userName = `${user.firstName || ''} ${user.lastName || ''}`;
  return (
    <>
      <Menu $isMobile={true}>
        <MenuInner>
          <MenuNavLinkLogo to='/voting'>
            <img
              src='/light-logo.svg'
              alt='MoodMetrics'
              style={{ height: '40px' }}
            />
          </MenuNavLinkLogo>
          <LangSwitcher isHeader />
          <MenuNavLinkProfile to='/my-profile'>
            {width > 767 && (
              <MenuNavLinkProfileName>{userName}</MenuNavLinkProfileName>
            )}
            {profileIcon}
          </MenuNavLinkProfile>
        </MenuInner>
      </Menu>
      <RoutesWrapper $isMobile={true}>
        <Routes>
          <Route path='/' element={<UserVoting />} />
          <Route path='*' element={<NotFound />} />
          <Route path='/voting//*' element={<UserVoting />} />
          <Route path='/user-profile//*' element={<UserOtherProfile />} />
          <Route
            path='/my-profile//*'
            element={<MyProfile user={user} handleLogOut={handleLogOut} />}
          />
        </Routes>
      </RoutesWrapper>
    </>
  );
};

const AdminRoutes = ({ user, handleLogOut }) => {
  const { width } = useWindowSize();
  const userName = `${user.firstName || ''} ${user.lastName || ''}`;

  const [aside, setAside] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setAside(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleAside = () => {
    setAside(!aside);
  };

  const handleAsideResponsive = () => {
    if (width < 1200) {
      setAside(!aside);
    }
  };

  return (
    <>
      <AsideMenu
        handleAside={handleAside}
        handleAsideResponsive={handleAsideResponsive}
        width={width}
        aside={aside}
        menuItems={adminMenuItems}
      />
      <Menu $isAside={aside}>
        <MenuInner>
          {!aside && (
            <MenuNavLinkLogo to='/'>
              <img
                src='/light-logo.svg'
                alt='MoodMetrics'
                style={{ height: '40px' }}
              />
            </MenuNavLinkLogo>
          )}
          <MenuNavLinkProfile to='/my-profile'>
            {width > 767 && (
              <MenuNavLinkProfileName>{userName}</MenuNavLinkProfileName>
            )}
            {profileIcon}
          </MenuNavLinkProfile>
          {width < 1200 && (
            <MenuNavLinkBurger onClick={handleAside}>
              <span />
            </MenuNavLinkBurger>
          )}
        </MenuInner>
      </Menu>
      <RoutesWrapper $isAside={aside}>
        {width < 768 && <Breadcrumbs />}
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route path='/' element={<AdminHome />} />
          <Route path='/information//*' element={<AdminInformation />} />
          <Route path='/metrics//*' element={<AdminMetrics />} />
          <Route path='/faq//*' element={<AdminFAQ />} />

          {/* Access to User components */}
          <Route path='/voting//*' element={<UserVoting />} />
          <Route path='/user-profile//*' element={<UserOtherProfile />} />
          <Route
            path='/my-profile//*'
            element={<MyProfile user={user} handleLogOut={handleLogOut} />}
          />
        </Routes>
      </RoutesWrapper>
    </>
  );
};

const RoutesWrapper = styled.div`
  padding-top: 80px;

  @media (min-width: 1200px) and (max-width: 1700px) {
    padding-left: 80px;

    ${(props) =>
      props.$isAside &&
      `
    padding-left: 200px;
  `}

    ${(props) =>
      props.$isMobile &&
      `
    padding-left: 0;
  `}
  }

  @media ${MOBILE} {
    padding-top: 110px;
  }
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  ${menuStyles};

  a {
    color: #fff;
  }

  @media ${MOBILE} {
    margin-bottom: 30px;
  }

  @media (min-width: 1200px) and (max-width: 1700px) {
    padding-left: 80px;

    ${(props) =>
      props.$isAside &&
      `
    padding-left: 200px;
  `}

    ${(props) =>
      props.$isMobile &&
      `
    padding-left: 0;
  `}
  }
`;

const MenuInner = styled(Container)`
  ${menuInnerStyles};

  @media ${MOBILE} {
    flex-wrap: wrap;
    align-items: center;
  }
`;

const MenuNavLink = styled(NavLink)`
  ${activeLinkStyles};
`;

const MenuNavLinkLogo = styled(MenuNavLink)`
  border-bottom: none !important;

  display: flex;
  align-items: center;
  margin-right: auto;
`;

const MenuNavLinkProfile = styled(MenuNavLink)`
  border-bottom: none !important;
  padding: 0;
  margin-left: 30px;
  display: flex;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;

    path {
      fill: #fff;
    }
  }
`;

const MenuNavLinkBurger = styled.div`
  ${burgerStyles};

  margin-left: 25px;
`;

const MenuNavLinkProfileName = styled.span`
  max-width: 235px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
`;

const GlobalStyles = createGlobalStyle`
  body, input, select, textarea {
    font-family: ${MAIN_FONT};
  }
  
  button {
    font-family: ${SECONDARY_FONT};
  }
  
  body {
    --primary-color-1: ${MAIN_COLOR_1};
    --primary-color-2: ${MAIN_COLOR_2};
    --grey-color: ${COLOR_GREY};
  }
`;

export default App;
