import React from 'react';
import Container from '../UI/Container';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { buttonHover, buttonStyles } from '../UI/UI';

const NotFound = () => {
  return (
    <NotFoundWrapper>
      <Container>
        <h1>404 :(</h1>
        <h2>Oops...this page doesn't exist.</h2>
        <NotFoundNavLink to='/'>Return to homepage</NotFoundNavLink>
      </Container>
    </NotFoundWrapper>
  );
};

const NotFoundWrapper = styled.section`
  padding: 40px 0;

  h1 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  h2 {
    margin-bottom: 30px;
  }
`;

const NotFoundNavLink = styled(NavLink)`
  ${buttonStyles};
  ${buttonHover};

  max-width: 300px;
`;

export default NotFound;
