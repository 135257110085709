import * as Yup from 'yup';

export const validationSchemaRegistration = Yup.object({
  firstName: Yup.string()
    .matches(/^[A-Za-zА-яа-я]+$/, 'Only letters')
    .min(2, 'At least 2 characters')
    .max(50, 'No more than 50 characters')
    .required('First name is required'),

  lastName: Yup.string()
    .matches(/^[A-Za-zА-яа-я]+$/, 'Only letters')
    .min(2, 'At least 2 characters')
    .max(50, 'No more than 50 characters')
    .required('Last name is required'),

  email: Yup.string()
    .email('Invalid. Use format: username@domain.xxx')
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      'Invalid. Use format: username@domain.xxx',
    )
    .required('Email is required'),

  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,20}$/,
      '8-20 characters. At least 1 number and 1 uppercase letter',
    )
    .required('Password is required'),
});

export const validationSchemaLogin = Yup.object({
  email: Yup.string()
    .email('Invalid. Use format: username@domain.xxx')
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      'Invalid. Use format: username@domain.xxx',
    )
    .required('Email is required'),

  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,20}$/,
      '8-20 characters. At least 1 number and 1 uppercase letter',
    )
    .required('Password is required'),
});
