import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { activeLinkStyles, burgerStyles } from '../UI/UI';
import { closeIcon } from '../UI/Icons';
import { useTranslation } from 'react-i18next';
import LangSwitcher from '../LangSwitcher/LangSwitcher';
import { DESKTOP, MAIN_COLOR_1, TO_DESKTOP } from '../UI/Variables';
import { menuIcons } from '../UI/MenuIcons';

const AsideMenu = ({
  handleAside,
  handleAsideResponsive,
  width,
  aside,
  menuItems,
  isUser,
}) => {
  const { t } = useTranslation();

  return (
    <AsideWrapper $isVisible={aside}>
      <Aside>
        {width < 1200 ? (
          <Close onClick={handleAside}>{closeIcon}</Close>
        ) : (
          <MenuNavLinkBurger
            onClick={handleAside}
            $isVisible={aside}
            $isUser={isUser}>
            <span />
          </MenuNavLinkBurger>
        )}
        <MenuNavLinkLogoWrapper>
          {(width < 1200 || aside) && (
            <MenuNavLinkLogo to='/' onClick={handleAsideResponsive}>
              <img
                src='/light-logo.svg'
                alt='MoodMetrics'
                style={{ height: '40px' }}
              />
            </MenuNavLinkLogo>
          )}
        </MenuNavLinkLogoWrapper>
        <AsideMenuNavigation $isVisible={aside}>
          {menuItems.map((menuItem) => {
            if (menuItem.subMenus && menuItem.subMenus.length > 0) {
              return (
                <MenuNavLinkWrapper key={menuItem.route}>
                  <MenuNavLink
                    to={menuItem.route}
                    onClick={handleAsideResponsive}
                    $isVisible={aside}>
                    {menuIcons[`${menuItem.name}_icon`]}
                    <span className='menu_item_text'>
                      {' '}
                      {t(`ADMIN.MENU_ITEM.${menuItem.shortName.toUpperCase()}`)}
                    </span>
                  </MenuNavLink>
                  {menuItem.subMenus.map((subMenu) => (
                    <MenuNavLinkNested
                      key={subMenu.route}
                      to={subMenu.route}
                      onClick={handleAsideResponsive}
                      $isVisible={aside}>
                      {menuIcons[`${subMenu.name}_icon`]}
                      <span className='menu_item_text'>
                        {t(
                          `ADMIN.MENU_ITEM.${subMenu.shortName.toUpperCase()}`,
                        )}
                      </span>
                    </MenuNavLinkNested>
                  ))}
                </MenuNavLinkWrapper>
              );
            } else {
              return (
                <MenuNavLink
                  key={menuItem.route}
                  to={menuItem.route}
                  onClick={handleAsideResponsive}
                  $isVisible={aside}>
                  {menuIcons[`${menuItem.name}_icon`]}
                  <span className='menu_item_text'>
                    {t(`ADMIN.MENU_ITEM.${menuItem.shortName.toUpperCase()}`)}
                  </span>
                </MenuNavLink>
              );
            }
          })}
          {(width < 1200 || aside) && (
            <AsideMenuLangSwitcher>
              <LangSwitcher />
            </AsideMenuLangSwitcher>
          )}
        </AsideMenuNavigation>
      </Aside>
      <Overlay onClick={handleAside} />
    </AsideWrapper>
  );
};

const AsideWrapper = styled.div`
  @media ${TO_DESKTOP} {
    ${(props) =>
      !props.$isVisible &&
      `
    display: none;
  `}
  }
`;

const Aside = styled.div`
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  animation-name: slideIn;
  border-right: 1px solid grey;
`;

const AsideMenuNavigation = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;

  overflow: auto;
  min-height: calc(100vh - 80px);
  background-color: rgba(232, 232, 232, 0.85);

  @media ${DESKTOP} {
    ${(props) => props.$isVisible && `min-width: 200px;`}
  }

  @media ${TO_DESKTOP} {
    background-color: #f4f4f4;
  }

  &:hover {
    .menu_item_text {
      max-width: 300px;
      opacity: 1;
      padding-inline-start: 10px;
    }
  }
`;

const MenuNavLinkBurger = styled.div`
  ${burgerStyles};

  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 1;

  &::before {
    width: 18px;
  }

  &::after {
    width: 24px;
  }

  &:hover {
    &::after,
    &::before {
      width: 30px;
    }
  }

  @media ${DESKTOP} {
    ${(props) =>
      !props.$isVisible &&
      `
      justify-content: flex-start;  
      right: auto;
      left: 25px;
    `}

    @media ${DESKTOP} {
      ${(props) => !props.$isVisible && props.$isUser && `left: 15px;`}
    }
  }
`;

const MenuNavLinkWrapper = styled.div``;

const MenuNavLink = styled(NavLink)`
  ${activeLinkStyles};

  margin-bottom: 15px;
  color: #000;
  text-decoration: none;
  border-bottom: 0 !important;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    display: block;
    padding-inline-start: 10px;
  }

  svg {
    width: 20px;
    height: 20px;
    path,
    rect,
    circle {
      fill: var(--primary-color-1);
      stroke: var(--primary-color-1);
      transition: all 0.2s;
    }
  }

  @media ${DESKTOP} {
    span {
      transition: all 0.2s;
      white-space: nowrap;

      ${(props) =>
        !props.$isVisible &&
        `
    overflow: hidden;
    max-width: 0;
    opacity: 0;
    padding-inline-start: 0;
  `}
    }

    &:hover {
      svg {
        path,
        rect,
        circle {
          fill: var(--primary-color-2);
          stroke: var(--primary-color-2);
        }
      }
    }
  }
`;

const MenuNavLinkNested = styled(MenuNavLink)`
  padding-left: 20px;
`;

const MenuNavLinkLogoWrapper = styled.div`
  padding: 15px 20px;
  background-color: ${MAIN_COLOR_1};
  min-height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media ${TO_DESKTOP} {
    background-color: var(--primary-color-1);
  }
`;

const MenuNavLinkLogo = styled(MenuNavLink)`
  border-bottom: none !important;
  margin-bottom: 0;
`;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;

  @media ${DESKTOP} {
    display: none;
  }
`;

const Close = styled.div`
  svg {
    width: 25px;
    height: 25px;

    path {
      fill: #fff;
    }
  }

  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 25px;
  right: 30px;
`;

const AsideMenuLangSwitcher = styled.div`
  display: flex;
  margin-top: 10px;
`;

export default AsideMenu;
