import React from 'react';
import Container from '../UI/Container';
import styled from 'styled-components';
// import { DESKTOP, SECONDARY_FONT } from '../UI/Variables';
import 'rc-slider/assets/index.css';
// import { NavLink } from 'react-router-dom';
import { logOutIcon, profileIcon } from '../UI/Icons';
import { useTranslation } from 'react-i18next';

const MyProfile = ({ user, handleLogOut }) => {
  const { t } = useTranslation();
  const profileFieldsTitles = [
    t('PROFILE.FIELD_TITLE.FIRST_NAME'),
    t('PROFILE.FIELD_TITLE.LAST_NAME'),
    //   'Job Title'
    // 'Department',
    // 'Date of birth',
    // 'Phone Number',
    t('PROFILE.FIELD_TITLE.EMAIL'),
    // 'Salary',
    // 'Seniority',
    // 'About Me',
  ];
  const profileData = {
    firstName: user.firstName,
    lastName: user.lastName,
    // department: 'Marketing',
    // dateOfBirth: '01.09.1987',
    // phoneNumber: '+123456789',
    email: user.email,
    // salary: '2000$',
    // seniority: '3y 4m',
    // about:
    //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco',
  };
  return (
    <MyProfileWrapper>
      <Container>
        <MyProfileInner>
          {/*<EditButton>Edit my profile</EditButton>*/}
          <MyProfileTop>
            <Avatar>
              {profileIcon}
              <AvatarName>
                {profileData.firstName} {profileData.lastName}
              </AvatarName>
            </Avatar>
          </MyProfileTop>
          <MyProfileFields>
            {Object.entries(profileData).map(([key, value], index) => (
              <MyProfileField key={key}>
                {profileFieldsTitles[index]} <span>{value}</span>
              </MyProfileField>
            ))}
            <MyProfileField>
              <span>
                <b onClick={handleLogOut}>
                  {logOutIcon} {t('PROFILE.LOG_OUT')}
                </b>
              </span>
            </MyProfileField>
          </MyProfileFields>
        </MyProfileInner>
      </Container>
    </MyProfileWrapper>
  );
};

const MyProfileWrapper = styled.div`
  padding: 60px 0;
`;

// const EditButton = styled(NavLink)`
//   font-family: ${SECONDARY_FONT};
//   font-weight: 700;
//   text-decoration: none;
//   border-bottom: 2px solid rgba(0, 0, 0, 0);
//   color: var(--primary-color-1);
//
//   @media ${DESKTOP} {
//     &:hover {
//       border-bottom: 2px solid var(--primary-color-2);
//     }
//   }
// `;

const MyProfileInner = styled.div`
  max-width: 500px;
  margin: auto;
`;

const MyProfileTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`;

const Avatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img,
  svg {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
`;

const AvatarName = styled.div`
  font-size: 23px;
  font-weight: 700;
`;

const MyProfileFields = styled.div``;

const MyProfileField = styled.div`
  font-size: 14px;
  color: grey;

  span {
    color: #000;
    font-weight: 400;
    display: block;
    margin-top: 3px;
    font-size: 17px;
  }

  b {
    cursor: pointer;
    display: inline-flex;
    gap: 7px;
    align-items: center;
    margin-top: 15px;
    margin-left: -5px;

    svg {
      width: 38px;

      path {
        fill: var(--primary-color-1);
        transition: all 0.3s;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-color);
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  b:hover {
    svg {
      #log_out_icon_arrow {
        transform: translateX(-40px);
      }

      path {
        fill: var(--primary-color-2);
      }
    }
  }
`;

export default MyProfile;
