import React, { useState, useEffect, useRef } from 'react';
import api from 'services/api';
import Container from '../../UI/Container';
import styled from 'styled-components';
import { editIcon, deleteIcon, plusIcon } from '../../UI/Icons';
import { buttonStyles, greyBlock } from '../../UI/UI';
import { Trans, useTranslation } from 'react-i18next';
import Popup from '../../UI/Popup';
import { FROM_TABLET, MOBILE } from '../../UI/Variables';

const InformationDepartments = () => {
  const { t } = useTranslation();

  const [departmentsList, setDepartmentsList] = useState([]);

  const inputEditRef = useRef(null);
  const [editIndex, setEditIndex] = useState(null);
  const [updatedDepartment, setUpdatedDepartment] = useState('');
  const [newDepartmentName, setNewDepartmentName] = useState('');

  const [newBlockVisible, setNewBlockVisible] = useState(false);
  const inputNewRef = useRef(null);

  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);

  const getDepartments = async () => {
    try {
      const response = await api.get('/api/departments');
      setDepartmentsList(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    if (editIndex !== null) {
      inputEditRef.current.focus();
    }
  }, [editIndex]);

  const handleEditClick = (index) => {
    setEditIndex(index);
    setUpdatedDepartment(departmentsList[index].name);
  };

  const handleEditInputChange = (event) => {
    setUpdatedDepartment(event.target.value);
  };

  const handleEditSaveClick = async (index) => {
    const updatedDepartments = [...departmentsList];

    updatedDepartments[index] = {
      ...updatedDepartments[index],
      name: updatedDepartment,
    };

    setDepartmentsList(updatedDepartments);

    try {
      const departmentToUpdate = updatedDepartments[index];
      const response = await api.patch(
        `/api/departments/${departmentToUpdate.id}`,
        {
          name: departmentToUpdate.name,
        },
      );

      console.log('Department saved successfully.', response.data);
    } catch (error) {
      console.error('Error while saving department.', error);
    }

    setEditIndex(null);
  };

  const handleNewBlockOpen = () => {
    setNewBlockVisible(!newBlockVisible);
  };

  useEffect(() => {
    if (newBlockVisible && inputNewRef.current) {
      inputNewRef.current.focus();
    }
  }, [newBlockVisible]);

  const handleNewInputChange = (event) => {
    setNewDepartmentName(event.target.value);
  };

  const handleNewInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleNewSaveClick();
    }
  };

  const handleNewSaveClick = async () => {
    if (newDepartmentName.trim() === '') {
      return; // Don't proceed if the input is empty
    }

    try {
      await api.post('/api/departments', {
        name: newDepartmentName,
      });

      getDepartments();
      setNewDepartmentName('');
      setNewBlockVisible(false);
    } catch (error) {
      console.error('Error while adding department:', error);
    }
  };

  const handleDeletePopupClose = (index, name) => {
    setDeleteIndex(index);
    setDeleteItem(name);
    setDeletePopupVisible(!deletePopupVisible);
  };

  const handleDeleteClick = async (index) => {
    const departmentToDelete = departmentsList[index];

    try {
      await api.delete(`/api/departments/${departmentToDelete.id}`);

      // Remove department from the state after successful deletion
      const updatedDepartments = departmentsList.filter(
        (department) => department.id !== departmentToDelete.id,
      );

      setDepartmentsList(updatedDepartments);
      console.log('Department deleted successfully.');
      handleDeletePopupClose();
    } catch (error) {
      console.error('Error while deleting department:', error);
      handleDeletePopupClose();
    }
  };

  return (
    <>
      <Container>
        <Add>
          <AddOption onClick={handleNewBlockOpen}>
            <AddOptionIcon $isOpened={newBlockVisible}>
              {plusIcon}
            </AddOptionIcon>
            {t('ADMIN.INFORMATION.DEPARTMENTS.NEW_BUTTON')}
          </AddOption>
          {newBlockVisible && (
            <>
              <AddTitle>
                {t('ADMIN.INFORMATION.DEPARTMENTS.NEW_TITLE')}
              </AddTitle>
              <AddInput
                type='text'
                ref={inputNewRef}
                placeholder='Department'
                value={newDepartmentName}
                onChange={handleNewInputChange}
                onKeyDown={handleNewInputKeyDown}
              />
              <AddSave onClick={handleNewSaveClick}>
                {t('ADMIN.INFORMATION.DEPARTMENTS.NEW_SUBMIT')}
              </AddSave>
            </>
          )}
        </Add>
        <ListTitle>{t('ADMIN.INFORMATION.DEPARTMENTS.LIST_TITLE')}</ListTitle>
        <List>
          {departmentsList && departmentsList.length > 0 ? (
            departmentsList.map((department, index) => (
              <Item key={index} $active={editIndex === index}>
                {editIndex !== index && (
                  <>
                    <ItemTitle>{department.name}</ItemTitle>
                    <ItemEdit onClick={() => handleEditClick(index)}>
                      {editIcon}
                    </ItemEdit>
                  </>
                )}
                {editIndex === index && (
                  <EditContainer>
                    <EditInput
                      ref={inputEditRef}
                      type='text'
                      value={updatedDepartment}
                      onChange={handleEditInputChange}
                    />
                    <SaveButton onClick={() => handleEditSaveClick(index)}>
                      {t('ADMIN.INFORMATION.DEPARTMENTS.EDIT_SAVE')}
                    </SaveButton>
                    <CancelButton onClick={() => setEditIndex(null)}>
                      {t('ADMIN.INFORMATION.DEPARTMENTS.EDIT_CANCEL')}
                    </CancelButton>
                  </EditContainer>
                )}
                <ItemDelete
                  onClick={() =>
                    handleDeletePopupClose(index, department.name)
                  }>
                  {deleteIcon}
                </ItemDelete>
              </Item>
            ))
          ) : (
            <li>{t('ADMIN.INFORMATION.DEPARTMENTS.EMPTY_ERROR')}</li>
          )}
        </List>
      </Container>
      {deletePopupVisible && (
        <DeletePopup>
          <Popup handleClose={() => handleDeletePopupClose()}>
            <p>
              <Trans
                i18nKey='ADMIN.INFORMATION.DEPARTMENTS.POPUP_TITLE'
                values={{ name: deleteItem }}
              />
            </p>
            <PopupButtonWrapper>
              <button
                className='yes'
                onClick={() => handleDeleteClick(deleteIndex)}>
                {t('POPUP.OPTION.YES')}
              </button>
              <button onClick={() => handleDeletePopupClose()}>
                {t('POPUP.OPTION.CANCEL')}
              </button>
            </PopupButtonWrapper>
          </Popup>
        </DeletePopup>
      )}
    </>
  );
};

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 60px 0;

  @media ${FROM_TABLET} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`;

const ListTitle = styled.div`
  margin-bottom: 15px;
  font-weight: 700;
`;

const Item = styled.li`
  ${greyBlock};

  display: flex;
  align-items: center;

  ${(props) =>
    props.$active &&
    `
    background-color: #fff;
    box-shadow: 3px 4px 10px 0 #E4E4E4 inset;
  `}

  @media ${MOBILE} {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

const ItemTitle = styled.div`
  margin-right: 50px;
`;

const ItemEdit = styled.div`
  cursor: pointer;
  margin-left: auto;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: #266ac4;
    }
  }
`;

const ItemDelete = styled.div`
  cursor: pointer;
  margin-left: 30px;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: #b20000;
    }
  }
`;

const EditContainer = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
  width: 100%;
`;

const EditInput = styled.input`
  background-color: rgba(0, 0, 0, 0);
  border: none !important;
  outline: none !important;
  font-size: 16px;
  font-style: italic;
  width: 100%;
`;

const CancelButton = styled.button`
  ${buttonStyles};

  padding: 2px 10px;
  border-radius: 5px;
  font-weight: 700;
`;

const SaveButton = styled(CancelButton)`
  background-color: #b20000;
  margin-inline-end: 15px;

  &:hover {
    background-color: #b20000;
  }
`;

const Add = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
`;

const AddOption = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
`;

const AddOptionIcon = styled.div`
  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;

    ${(props) =>
      props.$isOpened &&
      `
    transform: rotate(45deg);
  `}
  }
`;

const AddTitle = styled.div`
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 20px;
`;

const AddInput = styled.input`
  margin-bottom: 10px;
  width: 300px;
  padding: 9px 20px;
  border-radius: 10px;
  outline: 0;
  border: 1px solid grey;
  font-size: 16px;
`;

const AddSave = styled.button`
  ${buttonStyles};

  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
`;

const DeletePopup = styled.div`
  margin-bottom: 15px;
  font-weight: 700;
`;

const PopupButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default InformationDepartments;
