import React from 'react';
import Container from '../../UI/Container';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { greyBlock } from '../../UI/UI';
import { MOBILE } from '../../UI/Variables';
import { Trans } from 'react-i18next';
import menuItems from '../menuItems';

const InformationMain = () => {
  return (
    <MainWrapper>
      <Container>
        <MainInner>
          <MainInfo>
            <Trans
              i18nKey='ADMIN.INFORMATION.MAIN.DESCRIPTION'
              components={{ b: <b /> }}
            />
            <div>
              {menuItems.map((menuItem) => {
                if (menuItem.shortName === 'information' && menuItem.subMenus) {
                  return menuItem.subMenus.map((subMenu) => (
                    <InformationNavLink
                      to={subMenu.shortName}
                      key={subMenu.shortName}>
                      <Trans
                        i18nKey={`ADMIN.INFORMATION.MAIN.${subMenu.shortName.toUpperCase()}`}
                        components={{ b: <b /> }}
                      />
                    </InformationNavLink>
                  ));
                } else {
                  return null;
                }
              })}
            </div>
          </MainInfo>
          <MainImage>
            <img src='/information-1.jpg' alt='MoodMetrics' />
          </MainImage>
        </MainInner>
      </Container>
    </MainWrapper>
  );
};

const InformationNavLink = styled(NavLink)`
  ${greyBlock};

  margin-top: 15px;
  display: block;
  text-decoration: none;

  b {
    text-decoration: underline;
  }
`;

const MainWrapper = styled.div`
  padding-bottom: 30px;
`;

const MainInner = styled.div`
  display: flex;
  gap: 50px;

  @media ${MOBILE} {
    flex-direction: column;
    gap: 25px;
  }
`;

const MainInfo = styled.div`
  width: 50%;
  flex-shrink: 0;

  p:not(:last-child) {
    margin-bottom: 20px;
  }

  @media ${MOBILE} {
    width: 100%;
  }
`;

const MainImage = styled.div``;

export default InformationMain;
