import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ua from './locales/ua.json';
import ru from './locales/ru.json';

const currentLanguage = () => {
  let lng = 'en';
  if (sessionStorage.getItem('lng')) {
    lng = sessionStorage.getItem('lng');
  }
  return lng;
};

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    ua: { translation: ua },
    ru: { translation: ru },
  },
  lng: currentLanguage(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
