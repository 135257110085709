import React, { useState } from 'react';
import Container from '../../UI/Container';
import styled from 'styled-components';
import { profileIcon } from '../../UI/Icons';
import { DESKTOP, SECONDARY_FONT } from '../../UI/Variables';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Hint from '../../UI/Hint';
import { buttonHover, buttonStyles, greyBlock } from '../../UI/UI';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Voting = () => {
  const { t } = useTranslation();
  const users = [
    'Oleksandr Petrov',
    'Vladimir Ivanov',
    'Katerina John',
    'Ivan Holmes',
  ];
  const [userIndex, setUserIndex] = useState(0);
  const usersAmount = users.length;
  const marks = {};
  for (let i = 1; i <= 10; i++) {
    marks[i] = i.toString();
  }

  const [value, setValue] = useState(5.5);
  const [iconId, setIconId] = useState(6);

  const handleSliderChange = (newValue) => {
    setValue(newValue);
    setIconId(Math.ceil(newValue));
  };

  const handleUserChange = (index, direction) => {
    if (direction === 'next') {
      setUserIndex(index + 1 === usersAmount ? 0 : index + 1);
    } else if (direction === 'prev') {
      setUserIndex(index === 0 ? usersAmount - 1 : index - 1);
    }
  };

  return (
    <VotingWrapper>
      <Container>
        <VotingInner>
          <VotingTop>
            <ProfileButton to='/user-profile'>
              {profileIcon}
              <span>{t('USER.VOTING.PROFILE.OPEN')}</span>
            </ProfileButton>
            <Avatar>
              <AvatarTitle>{t('USER.VOTING.VOTE_FOR')}</AvatarTitle>
              {/*<img src='/metrics-1.jpg' alt='MoodMetrics' />*/}
              {profileIcon}
              <AvatarName>{users[userIndex]}</AvatarName>
            </Avatar>
          </VotingTop>
          <VotingSlider>
            <p>
              {t('USER.VOTING.VOTE_TITLE')}&nbsp;
              <Hint>{t('USER.VOTING.VOTE_HINT')}</Hint>
            </p>
            <SliderValue>
              {value}
              <img src={`/rate-icon-${iconId}.jpg`} alt='rating' />
            </SliderValue>
            <SliderPanel>
              <Slider
                min={1}
                max={10}
                step={0.1}
                marks={marks}
                defaultValue={value}
                onChange={handleSliderChange}
              />
            </SliderPanel>
          </VotingSlider>
          <AddComment>
            <p>{t('USER.VOTING.COMMENT_TITLE')}</p>
            <AddCommentAreaWrapper>
              <AddCommentArea
                placeholder={t('USER.VOTING.COMMENT_PLACEHOLDER')}
                rows='4'
              />
            </AddCommentAreaWrapper>
          </AddComment>
          <Buttons>
            <NavButton onClick={() => handleUserChange(userIndex, 'prev')}>
              {`<`}{' '}
            </NavButton>
            <SkipButton>
              {t('USER.VOTING.SKIP_BUTTON')}{' '}
              <Hint color='white' position='right'>
                {t('USER.VOTING.SKIP_HINT')}
              </Hint>
            </SkipButton>
            <SubmitButton>{t('USER.VOTING.SUBMIT')}</SubmitButton>
            <NavButton onClick={() => handleUserChange(userIndex, 'next')}>
              {' '}
              {`>`}
            </NavButton>
          </Buttons>
        </VotingInner>
      </Container>
    </VotingWrapper>
  );
};

const VotingWrapper = styled.div`
  padding: 30px 0 60px;
`;

const VotingInner = styled.div`
  max-width: 500px;
  margin: auto;
`;

const VotingTop = styled.div`
  margin-bottom: 50px;
`;

const Avatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
  max-width: 265px;

  img,
  svg {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
`;

const AvatarTitle = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

const AvatarName = styled.div`
  font-size: 23px;
  font-weight: 700;
`;

const ProfileButton = styled(NavLink)`
  cursor: pointer;
  display: flex;
  text-align: start;
  align-items: flex-start;
  color: #000;
  text-decoration: none;
  margin-bottom: 10px;

  span {
    margin-inline-start: 10px;
    font-family: ${SECONDARY_FONT};
    font-weight: 700;
    text-decoration: none;
    color: var(--primary-color-1);
  }

  @media ${DESKTOP} {
    &:hover {
      span {
        border-bottom: 2px solid var(--primary-color-2);
      }
    }
  }

  svg {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    transition: all 0.3s;

    path {
      fill: var(--primary-color-1);
    }
  }

  &:hover {
    svg {
      transform: translateY(-3px) scale(1.01);
      box-shadow: 0 8px 9px -6px rgba(0, 0, 0, 0.5);
    }
  }
`;

const VotingSlider = styled.div`
  p {
    text-align: center;
  }
`;

const SliderValue = styled.div`
  ${greyBlock};

  margin: 30px auto 10px;

  width: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  font-family: ${SECONDARY_FONT};
  font-weight: 500;

  img {
    border-radius: 50%;
    max-width: 30px;
  }
`;

const SliderPanel = styled.div`
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 25px;
  text-align: center;

  .rc-slider-track,
  .rc-slider-tracks {
    background-color: var(--primary-color-1);
  }

  .rc-slider-dot-active,
  .rc-slider-handle {
    border-color: var(--primary-color-1);
  }

  .rc-slider-handle {
    width: 18px;
    height: 18px;
    margin-top: -7px;
    background-color: var(--primary-color-1);
    opacity: 1;
  }

  .rc-slider-mark-text {
    font-size: 16px;
  }
`;

const AddComment = styled.div`
  margin-top: 70px;
  display: flex;
  white-space: nowrap;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;

  p {
    margin-right: 15px;
    padding-top: 9px;
  }
`;

const AddCommentAreaWrapper = styled.div`
  position: relative;
  padding-left: 25px;
  width: 100%;
  margin-bottom: 20px;

  &:after {
    height: 21px;
    width: 21px;
    content: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%227%22%20height%3D%228%22%20viewBox%3D%220%200%207%208%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M6%204L1%201V7L6%204Z%22%20fill%3D%22white%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M1.5%200.716905V0H0.5V1.2831L5.02817%204L0.5%206.7169V8H1.5V7.2831L6.97183%204L1.5%200.716905Z%22%20fill%3D%22%23E1E1E1%22%2F%3E%3C%2Fsvg%3E');
    position: absolute;
    transform: scale(-3.5, 3.5);
    left: -16px;
    top: 7px;
  }
`;

const AddCommentArea = styled.textarea`
  width: 100%;
  padding: 10px 20px;
  border: 3px solid var(--grey-color);
  border-radius: 10px;
  min-height: 40px;
  resize: vertical;
  font-size: 16px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
`;

const NavButton = styled.button`
  ${buttonStyles};
  ${buttonHover};
`;

const SubmitButton = styled.button`
  ${buttonStyles};
  ${buttonHover};

  background-color: var(--primary-color-2);
`;

const SkipButton = styled(SubmitButton)`
  background-color: #9d9d9d;
  font-weight: 400;
`;

export default Voting;
