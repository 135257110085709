import styled from 'styled-components';
import { hintIcon } from './Icons';
import { MAIN_FONT } from './Variables';

const Hint = ({ children, color, position }) => {
  const isRight = position === 'right';
  return (
    <HintWrapper $color={color} $isRight={isRight}>
      {hintIcon}
      <span>{children}</span>
    </HintWrapper>
  );
};

const HintWrapper = styled.span`
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: 15px;
  height: 15px;

  span {
    display: none;
  }

  &:hover {
    span {
      pointer-events: none;
      display: block;
      text-align: start;
      position: absolute;
      top: 50%;
      right: 22px;
      color: #000;
      font-family: ${MAIN_FONT};

      background-color: #fff;
      box-shadow: -2px -2px 3px rgba(0, 0, 0, 0.09);
      font-size: 12px;
      transform: translateY(-50%);
      padding: 3px 5px;
      border: 1px solid var(--grey-color);
      border-radius: 5px;
      width: 200px;

      &:after {
        content: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%227%22%20height%3D%228%22%20viewBox%3D%220%200%207%208%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M6%204L1%201V7L6%204Z%22%20fill%3D%22white%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M1.5%200.716905V0H0.5V1.2831L5.02817%204L0.5%206.7169V8H1.5V7.2831L6.97183%204L1.5%200.716905Z%22%20fill%3D%22%23E1E1E1%22%2F%3E%3C%2Fsvg%3E');
        position: absolute;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
      }

      ${(props) =>
        props.$isRight &&
        `
        left: 22px;
        right: auto;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.09);
        
        &:after {
            right: auto;
            left: -6px;
            transform: translateY(-50%) scaleX(-1);
          }
      `}
    }
  }

  svg {
    width: 15px;
    height: 15px;

    path {
      fill: #ababab;

      ${(props) =>
        props.$color &&
        `
        fill: ${props.$color};
      `}
    }
  }
`;

export default Hint;
