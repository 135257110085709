import React from 'react';
import Container from '../../UI/Container';
import styled, { css } from 'styled-components';
import { MOBILE } from '../../UI/Variables';
import { editIcon, resetIcon } from '../../UI/Icons';

const InformationWeight = () => {
  const userMockData = [
    { name: 'Vladimir Kravchenko Jr', weight: 7 },
    { name: 'User Name - Super Long Name here', weight: 5 },
    { name: 'Alex', weight: 3 },
    { name: 'Den', weight: 4 },
    { name: 'Tom', weight: 7 },
    { name: 'Peter', weight: 5 },
    { name: 'Alex', weight: 3 },
    { name: 'Den', weight: 4 },
  ];
  const departmentMockData = [
    { name: 'Marketing', weight: 7 },
    { name: 'IT', weight: 5 },
    { name: 'Bar', weight: 3 },
    { name: 'Marketing', weight: 7 },
    { name: 'IT', weight: 5 },
    { name: 'Bar', weight: 3 },
    { name: 'Marketing', weight: 7 },
    { name: 'IT', weight: 5 },
    { name: 'Bar', weight: 3 },
  ];
  return (
    <WeightWrapper>
      <Container>
        <WeightInner>
          <UserWeightWrapper>
            <UserWeightRow>
              <UserWeightColumnTop>User</UserWeightColumnTop>
              <UserWeightColumnTop>Weight</UserWeightColumnTop>
            </UserWeightRow>
            <UserWeightData>
              {userMockData.map((user) => {
                return (
                  <UserWeightRow key={user.name}>
                    <UserWeightColumn>{user.name}</UserWeightColumn>
                    <UserWeightColumn>
                      {user.weight}
                      <ItemEdit>
                        {editIcon}
                        <span>Edit</span>
                      </ItemEdit>
                      <ItemDefault>
                        {resetIcon}
                        <span>Reset to default</span>
                      </ItemDefault>
                    </UserWeightColumn>
                  </UserWeightRow>
                );
              })}
            </UserWeightData>
          </UserWeightWrapper>
          <DepartmentWeightWrapper>
            <DepartmentWeightRow>
              <DepartmentWeightColumnTop>Department</DepartmentWeightColumnTop>
              <DepartmentWeightColumnTop>Weight</DepartmentWeightColumnTop>
            </DepartmentWeightRow>
            <DepartmentWeightData>
              {departmentMockData.map((department) => {
                return (
                  <DepartmentWeightRow key={department.name}>
                    <DepartmentWeightColumn>
                      {department.name}
                    </DepartmentWeightColumn>
                    <DepartmentWeightColumn>
                      {department.weight}
                      <ItemEdit>
                        {editIcon}
                        <span>Edit</span>
                      </ItemEdit>
                      <ItemDefault>
                        {resetIcon}
                        <span>Reset to default</span>
                      </ItemDefault>
                    </DepartmentWeightColumn>
                  </DepartmentWeightRow>
                );
              })}
            </DepartmentWeightData>
          </DepartmentWeightWrapper>
        </WeightInner>
      </Container>
    </WeightWrapper>
  );
};

const blockWrapper = css`
  border-radius: 10px;
  overflow: hidden;

  width: calc(50% - 15px);

  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey-color);

  @media ${MOBILE} {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const dataStyles = css`
  max-height: 283px;
  overflow: scroll;
`;

const columnStyles = css`
  padding: 10px 20px;
  border: 1px solid var(--grey-color);

  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
`;

const rowStyles = css`
  display: grid;
  grid-template-columns: 1.3fr 0.7fr;

  box-shadow: 3px 4px 20px 0 #e4e4e4 inset;
`;

const WeightWrapper = styled.div`
  padding-bottom: 60px;
`;

const WeightInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media ${MOBILE} {
    flex-wrap: wrap;
  }
`;

const UserWeightWrapper = styled.div`
  ${blockWrapper};
`;

const UserWeightData = styled.div`
  ${dataStyles};
`;

const UserWeightRow = styled.div`
  ${rowStyles};
`;

const UserWeightColumn = styled.div`
  ${columnStyles};
`;

const UserWeightColumnTop = styled(UserWeightColumn)`
  font-weight: 700;
`;

const DepartmentWeightWrapper = styled.div`
  ${blockWrapper};
`;

const DepartmentWeightData = styled.div`
  ${dataStyles};
`;

const DepartmentWeightRow = styled.div`
  ${rowStyles};
`;

const DepartmentWeightColumn = styled.div`
  ${columnStyles};
`;

const DepartmentWeightColumnTop = styled(DepartmentWeightColumn)`
  font-weight: 700;
`;

const ItemEdit = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: auto;

  svg {
    width: 15px;
    height: 15px;

    path {
      fill: #266ac4;
    }
  }

  span {
    display: none;
  }

  &:hover {
    span {
      pointer-events: none;
      display: block;
      background-color: #fff;
      font-size: 12px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      padding: 1px 2px;
      border: 1px solid var(--grey-color);
      border-radius: 5px;

      &:after {
        content: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%227%22%20height%3D%228%22%20viewBox%3D%220%200%207%208%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M6%204L1%201V7L6%204Z%22%20fill%3D%22white%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M1.5%200.716905V0H0.5V1.2831L5.02817%204L0.5%206.7169V8H1.5V7.2831L6.97183%204L1.5%200.716905Z%22%20fill%3D%22%23E1E1E1%22%2F%3E%3C%2Fsvg%3E');
        position: absolute;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

const ItemDefault = styled(ItemEdit)`
  margin-left: 15px;

  svg {
    path {
      fill: #b20000;
    }
  }
`;

export default InformationWeight;
