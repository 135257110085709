import { css } from 'styled-components';
import { DESKTOP, MAIN_FONT, MOBILE, SECONDARY_FONT } from './Variables';

export const headerMedium = css`
  font-size: 20px;
  font-weight: 700;

  @media ${MOBILE} {
    font-size: 18px;
  }
`;

export const headerBig = css`
  font-size: 25px;
  font-weight: 700;

  @media ${MOBILE} {
    font-size: 21px;
  }
`;

export const menuStyles = css`
  background: var(--primary-color-1);
  min-height: 80px;
  font-family: ${SECONDARY_FONT};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;

  a {
    font-weight: 700;
    text-decoration: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    color: var(--primary-color-1);

    @media ${DESKTOP} {
      &:hover {
        border-bottom: 2px solid var(--primary-color-2);
      }
    }
  }
`;

export const menuInnerStyles = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const burgerStyles = css`
  cursor: pointer;

  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  span {
    width: 30px;
    height: 3px;
    border-radius: 10px;
    display: block;
    background-color: #fff;
  }

  &::after,
  &::before {
    content: '';
    width: 30px;
    height: 3px;
    border-radius: 10px;
    display: block;
    background-color: #fff;
    transition: all 0.2s;
  }
`;

export const activeLinkStyles = css`
  &.active {
    border-bottom: 2px solid var(--primary-color-2);
  }
`;

export const buttonStyles = css`
  font-family: ${MAIN_FONT};
  color: #fff;
  text-decoration: none;
  background-color: var(--primary-color-1);
  border: none;
  outline: 0;
  cursor: pointer;
  transition: 0.3s all;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 7px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const greyBlock = css`
  color: black;
  border-radius: 10px;
  border: 1px solid var(--grey-color);
  background-color: #f5f5f5;
  box-shadow: 3px 4px 20px 0 #e4e4e4 inset;
  padding: 10px 20px;
`;

export const buttonHover = css`
  transition: all 0.3s;
  &:hover {
    transform: translateY(-3px) scale(1.01);
    box-shadow: 0 8px 9px -6px rgba(0, 0, 0, 0.5);
  }
`;

export const formErrorStyles = css`
  position: absolute;
  font-size: 13px;
  bottom: -15px;
  left: 0;
  color: red;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const formFieldWrapperStyles = css`
  position: relative;
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 5px;
  }

  span {
    ${formErrorStyles};
  }

  input {
    width: 100%;

    color: black;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
    background-color: #f5f5f5;
    padding: 10px 20px;
  }
`;
