import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../../UI/Container';
import { menuStyles, activeLinkStyles } from '../../UI/UI';
import { MOBILE, TABLET } from '../../UI/Variables';
import { useTranslation } from 'react-i18next';
import menuItems from '../menuItems';

const InformationNavigation = () => {
  const { t } = useTranslation();
  return (
    <Menu>
      {menuItems.map((menuItem) => {
        if (menuItem.shortName === 'information' && menuItem.subMenus) {
          return menuItem.subMenus.map((subMenu) => (
            <InformationNavLink key={subMenu.shortName} to={subMenu.shortName}>
              {t(`ADMIN.MENU_ITEM.${subMenu.shortName.toUpperCase()}`)}
            </InformationNavLink>
          ));
        } else {
          return null;
        }
      })}
    </Menu>
  );
};

const Menu = styled(Container)`
  ${menuStyles};

  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  padding-top: 30px;
  padding-bottom: 25px;

  @media ${TABLET} {
    gap: 30px;
  }

  @media ${MOBILE} {
    gap: 20px;
    display: none;
  }
`;

const InformationNavLink = styled(NavLink)`
  ${activeLinkStyles};
`;

export default InformationNavigation;
