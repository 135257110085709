import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { globeIcon, arrowIcon } from '../UI/Icons';
import { useTranslation } from 'react-i18next';
import { MAIN_FONT } from '../UI/Variables';

const LangSwitcher = ({ isHeader }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { i18n } = useTranslation();
  const dropdownRef = useRef(null); // створюємо реф для компонента

  const handleDropdown = () => {
    setIsVisible(!isVisible);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleDropdown();
    sessionStorage.setItem('lng', lng);
  };

  // Event handler to close dropdown when clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <LangSwitcherWrapper ref={dropdownRef}>
      <ActiveLanguage onClick={handleDropdown}>
        {globeIcon}
        {i18n.language}
        <ActiveLanguageArrow $isVisible={isVisible}>
          {arrowIcon}
        </ActiveLanguageArrow>
      </ActiveLanguage>
      {isVisible && (
        <LanguageDropdown $isHeader={isHeader}>
          <button onClick={() => changeLanguage('en')}>English</button>
          <button onClick={() => changeLanguage('ua')}>Українська</button>
          <button onClick={() => changeLanguage('ru')}>русский</button>
        </LanguageDropdown>
      )}
    </LangSwitcherWrapper>
  );
};

const LangSwitcherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ActiveLanguage = styled.div`
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;

    path {
      fill: #000;
    }
  }

  padding: 5px 7px;
  border: 1px solid #ccc;
  border-radius: 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-right: auto;

  background-color: #fff;
  color: #000;
`;

const ActiveLanguageArrow = styled.div`
  svg {
    width: 13px;
    height: 13px;

    path {
      fill: #000;
    }
  }

  ${(props) =>
    props.$isVisible &&
    `
    transform: rotate(180deg);
  `}
`;

const LanguageDropdown = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 7px #ccc;
  margin-top: 5px;

  button {
    cursor: pointer;
    text-align: start;
    outline: 0;
    border: 0;
    padding: 8px 13px;
    font-family: ${MAIN_FONT};
    font-size: 16px;
    background-color: #fff;
    color: #000;

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }

    &:hover {
      background: #eee;
    }
  }

  ${(props) =>
    props.$isHeader &&
    `
      position: absolute;
      top: 100%;
  `}
`;

export default LangSwitcher;
