import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../../UI/Container';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  return (
    pathnames.length > 0 && (
      <BreadcrumbContainer>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          return (
            <BreadcrumbItem key={to}>
              <StyledLink to={to}>
                {t(`ADMIN.MENU_ITEM.${value.toUpperCase()}`)}
              </StyledLink>
            </BreadcrumbItem>
          );
        })}
      </BreadcrumbContainer>
    )
  );
};

const BreadcrumbContainer = styled(Container)`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const BreadcrumbItem = styled.div`
  &:not(:last-child)::after {
    content: '>';
    margin: 0 8px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #266ac4;

  &:hover {
    text-decoration: underline;
  }
`;

export default Breadcrumbs;
