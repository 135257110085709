export const MAIN_COLOR_1 = '#26a4e3';
export const MAIN_COLOR_2 = '#ff7c01';
export const COLOR_GREY = '#e1e1e1';
export const MAIN_FONT = "'Raleway', sans-serif";
export const SECONDARY_FONT = "'Nunito', sans-serif";
export const MOBILE = '(max-width: 767px)';
export const TABLET = '(min-width: 768px) and (max-width: 1199px)';
export const FROM_TABLET = '(min-width: 768px)';
export const DESKTOP = '(min-width: 1200px)';
export const TO_DESKTOP = '(max-width: 1199px)';
