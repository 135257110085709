import React, { useState } from 'react';
import api from 'services/api';
import Container from '../UI/Container';
import styled from 'styled-components';
import { buttonHover, buttonStyles, formFieldWrapperStyles } from '../UI/UI';
import { validationSchemaLogin } from '../../helpers/validationSchema';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

const Login = ({ setRole, onLogin, setSignUp }) => {
  const { t } = useTranslation();

  const [isError, setIsError] = useState(false);
  const [errorMessageKey, setErrorMessageKey] = useState('');

  const handleLogin = async (values) => {
    try {
      const response = await api.post('/api/auth/login', {
        email: values.email,
        password: values.password,
      });

      // Save the token
      localStorage.setItem('token', response.headers.authorization);

      // Settle the role
      const adminEmail = response.data.email;
      const defineRole =
        adminEmail === 'mood.metrics.tech@gmail.com' ? 'admin' : 'user';
      setRole(defineRole);
      localStorage.setItem('role', defineRole);
      onLogin(defineRole);

      setIsError(false);
    } catch (error) {
      setErrorMessageKey(error.response.data.message);
      setIsError(true);
      console.error('Error while logging in:', error);
    }
  };

  return (
    <LoginWrapper>
      <Container>
        <h2>Login To Your Account</h2>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchemaLogin}
          onSubmit={(values) => handleLogin(values)}>
          {({ errors, touched, values }) => (
            <Form>
              <FieldWrapper>
                <label htmlFor='email'>Email</label>
                <Field
                  name='email'
                  type='email'
                  style={
                    errors.email && touched.email && { borderColor: 'red' }
                  }
                />
                <ErrorMessage name='email' component='span' />
              </FieldWrapper>

              <FieldWrapper>
                <label htmlFor='password'>Password</label>
                <Field
                  name='password'
                  type='password'
                  style={
                    errors.password &&
                    touched.password && { borderColor: 'red' }
                  }
                />
                <ErrorMessage name='password' component='span' />
              </FieldWrapper>
              {isError && (
                <FormError>
                  <span>{t(`FORM_ERRORS.${errorMessageKey}`)}</span>
                </FormError>
              )}
              <ButtonWrapper>
                <FormButton type='submit'>Login</FormButton>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
        <SignUpWrapper>
          Don't have an account?{' '}
          <SignUpButton onClick={() => setSignUp(true)}>Sign Up</SignUpButton>
        </SignUpWrapper>
      </Container>
    </LoginWrapper>
  );
};

const LoginWrapper = styled.section`
  padding: 40px 0;

  h2 {
    margin-bottom: 30px;
    text-align: center;
  }
`;

const FieldWrapper = styled.div`
  ${formFieldWrapperStyles};

  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
`;

const FormButton = styled.button`
  ${buttonStyles};
  ${buttonHover};
  margin-right: auto;
  margin-left: auto;

  max-width: 375px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 30px;
`;

const FormError = styled.div`
  ${formFieldWrapperStyles};

  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  top: -17px;
`;

const SignUpWrapper = styled.div`
  margin-top: 50px;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding-top: 50px;
  border-top: 2px solid var(--grey-color);
  border-top-style: double;
`;

const SignUpButton = styled.button`
  ${buttonStyles};

  color: var(--primary-color-1);
  background-color: #fff;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export default Login;
