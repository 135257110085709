import styled from 'styled-components';
import { plusIcon } from './Icons';
import { buttonStyles } from './UI';
import { MOBILE } from './Variables';

const Popup = ({ children, handleClose }) => {
  return (
    <PopupWrapper>
      <PopupOverlay onClick={handleClose} />
      <PopupContent>
        <PopupCloseIcon onClick={handleClose}>{plusIcon}</PopupCloseIcon>
        {children}
      </PopupContent>
    </PopupWrapper>
  );
};

const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;
`;

const PopupOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
`;

const PopupCloseIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;

  svg {
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
  }
`;

const PopupContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  max-width: 500px;
  border-radius: 20px;
  padding: 40px 25px 30px;

  text-align: center;

  p {
    margin-bottom: 20px;
  }

  button {
    ${buttonStyles};

    &:not(:last-child) {
      margin-inline-end: 20px;
    }

    &.yes {
      background-color: #b20000;
    }
  }

  @media ${MOBILE} {
    max-width: 350px;
  }
`;

export default Popup;
