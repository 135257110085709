import React, { useEffect, useState } from 'react';
import api from 'services/api';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import Container from '../../UI/Container';
import {
  buttonHover,
  buttonStyles,
  greyBlock,
  headerMedium,
} from '../../UI/UI';
import { deleteIcon, editIcon } from '../../UI/Icons';
import Popup from '../../UI/Popup';
import Copy from '../../UI/Copy';
import { FROM_TABLET, MOBILE, TO_DESKTOP } from '../../UI/Variables';

const InformationSettings = () => {
  const { t } = useTranslation();

  const [votingsList, setVotingsList] = useState([]);
  const getVotings = async () => {
    try {
      const response = await api.get('/api/vote-events');
      setVotingsList(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getVotings();
  }, []);

  const mockFrequencies = [
    {
      value: 'week',
      text: t('ADMIN.INFORMATION.SETTINGS.FREQUENCY_WEEK'),
      isDisabled: true,
    },
    {
      value: 'month',
      text: t('ADMIN.INFORMATION.SETTINGS.FREQUENCY_MONTH'),
      isDisabled: false,
    },
    {
      value: 'quarter',
      text: t('ADMIN.INFORMATION.SETTINGS.FREQUENCY_QUARTED'),
      isDisabled: true,
    },
    {
      value: 'year',
      text: t('ADMIN.INFORMATION.SETTINGS.FREQUENCY_YEAR'),
      isDisabled: true,
    },
  ];
  const [frequency, setFrequency] = useState(mockFrequencies[1].value);
  const [selectedFrequency, setSelectedFrequency] = useState(1);
  const handleFrequency = (value, index, isDisabled) => {
    if (!isDisabled) {
      setFrequency(value);
      setSelectedFrequency(index);
    }
  };

  const [startDateMonth, setStartDateMonth] = useState(1);
  const [endDateMonth, setEndDateMonth] = useState(15);
  const handleStartDateMonthChange = (event) => {
    setStartDateMonth(event.target.value);
  };
  const handleEndDateMonthChange = (event) => {
    setEndDateMonth(event.target.value);
  };

  const [popupOpen, setPopupOpen] = useState(false);
  const [generatedLink, setGeneratedLink] = useState('');

  const domain = window.location.origin;
  const generatePopupLink = async () => {
    let newEventId = 0;
    try {
      const response = await api.post('/api/vote-events', {
        frequency: mockFrequencies[selectedFrequency].value,
        startDay: +startDateMonth,
        endDay: +endDateMonth,
      });
      newEventId = response.data.id;
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    const path = 'voting';
    const parameters = [{ key: 'event', value: newEventId }];

    const parametersString = parameters
      .map(
        (param) =>
          `${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}`,
      )
      .join('&');

    setGeneratedLink(`${domain}/${path}?${parametersString}`);
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(!popupOpen);
  };

  // Delete functionality for list of votings
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);

  const handleDeletePopupClose = (index) => {
    setDeleteIndex(index);
    setDeletePopupVisible(!deletePopupVisible);
  };

  const handleDeleteClick = async (index) => {
    const votingToDelete = votingsList[index];

    try {
      await api.delete(`/api/vote-events/${votingToDelete.id}`);

      // Remove department from the state after successful deletion
      const updatedVotings = votingsList.filter(
        (voting) => voting.id !== votingToDelete.id,
      );

      setVotingsList(updatedVotings);
      console.log('Voting deleted successfully.');
      handleDeletePopupClose();
    } catch (error) {
      console.error('Error while deleting department:', error);
      handleDeletePopupClose();
    }
  };

  return (
    <SettingsWrapper>
      <Container>
        <Stability>
          {t('ADMIN.INFORMATION.SETTINGS.STABILITY_TITLE')}
          <StabilityValue>
            1.5<StabilityEdit className='disabled'>{editIcon}</StabilityEdit>
          </StabilityValue>
        </Stability>
        <VotingPeriod>
          <VotingTitle>
            {t('ADMIN.INFORMATION.SETTINGS.VOTING_TITLE_1')}
          </VotingTitle>
          <FrequencyWrapper>
            {t('ADMIN.INFORMATION.SETTINGS.FREQUENCY_TITLE')}
            {mockFrequencies.map((frequency, index) => (
              <FrequencyOption
                key={index}
                onClick={() =>
                  handleFrequency(frequency.value, index, frequency.isDisabled)
                }
                $isSelected={selectedFrequency === index}
                className={frequency.isDisabled && 'disabled'}>
                {frequency.text}
              </FrequencyOption>
            ))}
          </FrequencyWrapper>
          {frequency === 'week' && (
            <DateWrapper>
              <DateBlock>
                {t('ADMIN.INFORMATION.SETTINGS.VOTING_START')}
                <select>
                  <option>Monday</option>
                  <option>Tuesday</option>
                  <option>Wednesday</option>
                  <option>Thursday</option>
                  <option>Friday</option>
                  <option>Saturday</option>
                  <option>Sunday</option>
                </select>
              </DateBlock>
              <DateBlock>
                {t('ADMIN.INFORMATION.SETTINGS.VOTING_END')}
                <select>
                  <option>Monday</option>
                  <option>Tuesday</option>
                  <option selected>Wednesday</option>
                  <option>Thursday</option>
                  <option>Friday</option>
                  <option>Saturday</option>
                  <option>Sunday</option>
                </select>
              </DateBlock>
            </DateWrapper>
          )}
          {frequency === 'month' && (
            <DateWrapper>
              <DateBlock>
                {t('ADMIN.INFORMATION.SETTINGS.VOTING_START')}
                <select onChange={handleStartDateMonthChange}>
                  {[...Array(28)].map((i, idx) => {
                    const index = idx + 1;
                    return <option key={index}>{index}</option>;
                  })}
                </select>
              </DateBlock>
              <DateBlock>
                {t('ADMIN.INFORMATION.SETTINGS.VOTING_END')}
                <select
                  onChange={handleEndDateMonthChange}
                  defaultValue={endDateMonth}>
                  {[...Array(28)].map((i, idx) => {
                    const index = idx + 1;
                    return <option key={index}>{index}</option>;
                  })}
                </select>
              </DateBlock>
            </DateWrapper>
          )}
          {frequency === 'quarter' && (
            <DateWrapper>
              <DateBlock>
                {t('ADMIN.INFORMATION.SETTINGS.VOTING_START')}
                <select>
                  {[...Array(31)].map((i, idx) => {
                    const index = idx + 1;
                    return <option key={index}>{index}</option>;
                  })}
                </select>
              </DateBlock>
              <DateBlock>
                {t('ADMIN.INFORMATION.SETTINGS.VOTING_END')}
                <select>
                  {[...Array(31)].map((i, idx) => {
                    const index = idx + 1;
                    return (
                      <option key={index} selected={index === 15}>
                        {index}
                      </option>
                    );
                  })}
                </select>
              </DateBlock>
            </DateWrapper>
          )}
          {frequency === 'year' && (
            <DateWrapper>
              <DateBlock>
                {t('ADMIN.INFORMATION.SETTINGS.VOTING_START')}
                <select>
                  {[...Array(31)].map((i, idx) => {
                    const index = idx + 1;
                    return <option key={index}>{index}</option>;
                  })}
                </select>
              </DateBlock>
              <DateBlock>
                {t('ADMIN.INFORMATION.SETTINGS.VOTING_END')}
                <select>
                  {[...Array(31)].map((i, idx) => {
                    const index = idx + 1;
                    return (
                      <option key={index} selected={index === 15}>
                        {index}
                      </option>
                    );
                  })}
                </select>
              </DateBlock>
            </DateWrapper>
          )}
          <SubmitButton onClick={() => generatePopupLink()}>
            {t('ADMIN.INFORMATION.SETTINGS.VOTING_NEW_BUTTON')}
          </SubmitButton>
          {popupOpen && (
            <Popup handleClose={() => handlePopupClose()}>
              Here is the link to your voting:
              <br />
              <br />
              <Copy>{generatedLink}</Copy>
            </Popup>
          )}
        </VotingPeriod>
        <VotingList>
          <VotingTitle>
            {t('ADMIN.INFORMATION.SETTINGS.VOTING_TITLE_2')}
          </VotingTitle>
          <List>
            {votingsList && votingsList.length > 0 ? (
              votingsList.map((voting, index) => (
                <Item key={index}>
                  <ItemTitle>
                    <Trans
                      i18nKey='ADMIN.INFORMATION.SETTINGS.VOTING_INFO'
                      values={{
                        frequency: t(
                          `ADMIN.INFORMATION.SETTINGS.FREQUENCY_${voting.frequency.toUpperCase()}`,
                        ),
                        startDay: voting.startDay,
                        endDay: voting.endDay,
                      }}
                      components={{ span: <span /> }}
                    />
                  </ItemTitle>
                  <ItemTitleLink>
                    <p>{t('ADMIN.INFORMATION.SETTINGS.VOTING_LINK')}</p>
                    <Copy isLight>
                      {domain}/voting?event={voting.id}
                    </Copy>
                  </ItemTitleLink>
                  <ItemDelete onClick={() => handleDeletePopupClose(index)}>
                    {deleteIcon}
                  </ItemDelete>
                </Item>
              ))
            ) : (
              <li>{t('departments_empty_list')}</li>
            )}
          </List>
          {deletePopupVisible && (
            <DeletePopup>
              <Popup handleClose={() => handleDeletePopupClose()}>
                <p>Are you sure you want to delete this voting?</p>
                <PopupButtonWrapper>
                  <button
                    className='yes'
                    onClick={() => handleDeleteClick(deleteIndex)}>
                    {t('POPUP.OPTION.YES')}
                  </button>
                  <button onClick={() => handleDeletePopupClose()}>
                    {t('POPUP.OPTION.CANCEL')}
                  </button>
                </PopupButtonWrapper>
              </Popup>
            </DeletePopup>
          )}
        </VotingList>
      </Container>
    </SettingsWrapper>
  );
};

const SettingsWrapper = styled.div``;

const Stability = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const StabilityValue = styled.div`
  ${greyBlock};

  margin-left: 20px;
  padding-left: 50px;
  padding-right: 35px;
  display: flex;
  align-items: center;
`;

const StabilityEdit = styled.div`
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    width: 15px;
    height: 15px;

    path {
      fill: #266ac4;
    }
  }
`;

const VotingPeriod = styled.div`
  margin-bottom: 40px;
`;

const VotingTitle = styled.div`
  ${headerMedium};

  margin-bottom: 15px;
`;

const FrequencyWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;

  @media ${MOBILE} {
    margin-bottom: 15px;
  }
`;

const FrequencyOption = styled.div`
  ${greyBlock};
  cursor: pointer;
  margin-left: 30px;
  transition: 0.3s all;

  ${(props) =>
    props.$isSelected &&
    `
      color: white;
      background-color: var(--primary-color-2);
      box-shadow: none;
      font-weight: 700;
  `}

  &:hover {
    box-shadow: none;
  }

  @media ${MOBILE} {
    margin-bottom: 15px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;

  @media ${MOBILE} {
    display: block;
  }
`;

const DateBlock = styled.div`
  &:not(:last-child) {
    margin-right: 30px;
  }

  select {
    ${greyBlock};

    cursor: pointer;
    margin-left: 15px;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: 3px 4px 20px 0 #f4f4f4 inset;
  }

  @media ${MOBILE} {
    &:not(:last-child) {
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
`;

const SubmitButton = styled.div`
  ${buttonStyles};
  ${buttonHover};

  max-width: 250px;
`;

const VotingList = styled.div``;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 60px 0;
`;

const Item = styled.li`
  ${greyBlock};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media ${MOBILE} {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
`;

const ItemTitle = styled.div`
  span {
    text-transform: lowercase;
  }

  @media ${FROM_TABLET} {
    width: calc(50% - 30px);
  }
`;

const ItemTitleLink = styled(ItemTitle)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  p {
    width: 120px;
  }

  @media ${TO_DESKTOP} {
    p {
      display: none;
    }
  }
`;

const ItemDelete = styled.div`
  cursor: pointer;
  margin-left: 30px;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: #b20000;
    }
  }

  @media ${MOBILE} {
    margin-left: auto;
  }
`;

const DeletePopup = styled.div`
  margin-bottom: 15px;
  font-weight: 700;
`;

const PopupButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default InformationSettings;
