import React from 'react';
import Container from '../../UI/Container';
import styled, { css } from 'styled-components';
import { MOBILE } from '../../UI/Variables';
import { editIcon } from '../../UI/Icons';

const InformationNormal = () => {
  const normalMockData = [
    { name: 'Vladimir Kravchenko Jr', salary: '700$', seniority: '1 year' },
    {
      name: 'User Name - Super Long Name here',
      salary: '600$',
      seniority: '2 years',
    },
    { name: 'Alex', salary: '1000$', seniority: '2 years' },
    { name: 'Den', salary: '1500$', seniority: '3 years' },
    { name: 'Tom', salary: '900$', seniority: '2 years' },
    { name: 'Peter', salary: '1300$', seniority: '4 years' },
    { name: 'Alex', salary: '1100$', seniority: '5 years' },
    { name: 'Den', salary: '900$', seniority: '4 years' },
  ];

  return (
    <NormalWrapper>
      <Container>
        <NormalInner>
          <UserNormalWrapper>
            <UserNormalRow>
              <UserNormalColumnTop>User</UserNormalColumnTop>
              <UserNormalColumnTop>Salary</UserNormalColumnTop>
              <UserNormalColumnTop>Seniority</UserNormalColumnTop>
            </UserNormalRow>
            <UserNormalData>
              {normalMockData.map((user) => {
                return (
                  <UserNormalRow key={user.name}>
                    <UserNormalColumn>{user.name}</UserNormalColumn>
                    <UserNormalColumn>
                      {user.salary}
                      <ItemEdit>
                        {editIcon}
                        <span>Edit</span>
                      </ItemEdit>
                    </UserNormalColumn>
                    <UserNormalColumn>
                      {user.seniority}
                      <ItemEdit>
                        {editIcon}
                        <span>Edit</span>
                      </ItemEdit>
                    </UserNormalColumn>
                  </UserNormalRow>
                );
              })}
            </UserNormalData>
          </UserNormalWrapper>
        </NormalInner>
      </Container>
    </NormalWrapper>
  );
};

const blockWrapper = css`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey-color);
`;

const dataStyles = css`
  max-height: 283px;
  overflow: scroll;
`;

const columnStyles = css`
  padding: 10px 20px;
  border: 1px solid var(--grey-color);

  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;

  @media ${MOBILE} {
    padding: 5px 7px;
    font-size: 15px;
    white-space: normal;
  }
`;

const rowStyles = css`
  display: grid;
  grid-template-columns: 0.44fr 0.28fr 0.28fr;

  box-shadow: 3px 4px 20px 0 #e4e4e4 inset;

  @media ${MOBILE} {
    grid-template-columns: 0.4fr 0.3fr 0.3fr;
  }
`;

const NormalWrapper = styled.div`
  padding-bottom: 60px;
`;

const NormalInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media ${MOBILE} {
    flex-wrap: wrap;
  }
`;

const UserNormalWrapper = styled.div`
  ${blockWrapper};
`;

const UserNormalData = styled.div`
  ${dataStyles};
`;

const UserNormalRow = styled.div`
  ${rowStyles};
`;

const UserNormalColumn = styled.div`
  ${columnStyles};
`;

const UserNormalColumnTop = styled(UserNormalColumn)`
  font-weight: 700;
`;

const ItemEdit = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: auto;

  svg {
    width: 15px;
    height: 15px;

    path {
      fill: #266ac4;
    }
  }

  span {
    display: none;
  }

  &:hover {
    span {
      pointer-events: none;
      display: block;
      background-color: #fff;
      font-size: 12px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      padding: 1px 2px;
      border: 1px solid var(--grey-color);
      border-radius: 5px;

      &:after {
        content: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%227%22%20height%3D%228%22%20viewBox%3D%220%200%207%208%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M6%204L1%201V7L6%204Z%22%20fill%3D%22white%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M1.5%200.716905V0H0.5V1.2831L5.02817%204L0.5%206.7169V8H1.5V7.2831L6.97183%204L1.5%200.716905Z%22%20fill%3D%22%23E1E1E1%22%2F%3E%3C%2Fsvg%3E');
        position: absolute;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

export default InformationNormal;
