export const menuItems = [
  {
    route: '/information',
    shortName: 'information',
    name: 'menu_information',
    subMenus: [
      {
        route: '/information/departments',
        shortName: 'departments',
        name: 'menu_departments',
      },
      // {
      //   route: '/information/users',
      //   shortName: 'users',
      //   name: 'menu_users',
      // },
      {
        route: '/information/weight',
        shortName: 'weight',
        name: 'menu_weight',
      },
      {
        route: '/information/normal',
        shortName: 'normal',
        name: 'menu_normal',
      },
      {
        route: '/information/settings',
        shortName: 'settings',
        name: 'menu_settings',
      },
    ],
  },
  {
    route: '/metrics',
    shortName: 'metrics',
    name: 'menu_metrics',
    subMenus: [
      {
        route: '/metrics/votes',
        shortName: 'votes',
        name: 'menu_votes',
      },
      {
        route: '/metrics/average',
        shortName: 'average',
        name: 'menu_average',
      },
    ],
  },
  {
    route: '/faq',
    shortName: 'faq',
    name: 'menu_faq',
  },
];

export default menuItems;
