import React from 'react';
import styled from 'styled-components';
import Container from '../UI/Container';
import { Trans } from 'react-i18next';
import { MOBILE } from '../UI/Variables';

const AdminHome = () => {
  return (
    <Wrapper>
      <Container>
        <Description>
          <Trans i18nKey='ADMIN.HOME.MAIN_TEXT' components={{ b: <b /> }} />
        </Description>
        <img
          src='/main-image-1.png'
          alt='MoodMetrics'
          style={{ width: '100%' }}
        />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;

  @media ${MOBILE} {
    padding-top: 0;
  }
`;

const Description = styled.div`
  margin-bottom: 30px;

  p:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export default AdminHome;
