import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  Navigation,
  Departments,
  // Users,
  Weight,
  Settings,
  Main,
  Normal,
} from './index';
import NotFound from '../../NotFound/NotFound';

const Information = () => {
  return (
    <>
      <Navigation />
      <Routes>
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={<Main />} />
        <Route path='departments' element={<Departments />} />
        {/*<Route path='users' element={<Users />} />*/}
        <Route path='weight' element={<Weight />} />
        <Route path='normal' element={<Normal />} />
        <Route path='settings' element={<Settings />} />
      </Routes>
    </>
  );
};

export default Information;
