import styled from 'styled-components';
import { TABLET, MOBILE } from './Variables';

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media ${TABLET} {
    max-width: 768px;
    padding: 0 9px;
  }

  @media ${MOBILE} {
    max-width: 360px;
    padding: 0 10px;
  }
`;

export default Container;
